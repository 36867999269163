import axios from 'axios';

const headers = {
  'Content-Type': 'application/json',
  // withCredentials: 'true',
};

const opts = {
  headers: headers,
};

const headersFile = {
  'Content-Type': 'multipart/form-data',
  // withCredentials: 'true',
};

const optsFile = {
  headers: headersFile,
};

class RequestService {
  getRequest(url: string): Promise<any> {
    return axios.get(url, opts);
  }

  postRequest(url: string, data: any): Promise<any> {
    return axios.post(url, data, opts);
  }
  
  postRequestFile(url: string, data: any): Promise<any> {
    return axios.post(url, data, optsFile);
  }

  putRequest(url: string, data: any) {
    return axios.put(url, data, opts);
  }

  patchRequest(url: string, data: any) {
    return axios.patch(url, data, opts);
  }

  deleteRequest(url: string) {
    return axios.delete(url, opts);
  }
}

export default new RequestService();
