import styled from 'styled-components'

const FlexColumn = styled.div `
    display: flex;
    flex-direction: column;
    width: 100%;
`

export default FlexColumn;

