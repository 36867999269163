import React, { useState } from 'react'
import { Box, Button, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useFormik, Formik, Form } from 'formik';
import ApiSheetService from '../../api/sheet/Sheet';
import data from "../../data/fr.json"
import { ICreneaux } from '../../data/data';
import validations from './validations';
import formulaire from './form';
import Logo from '../../asset/Logo.png';
import SosImg from '../../asset/SosImg.png';
import { Planning } from '../../component/Planning';
import { FormFieldFirst, FormFieldSecond } from '../../component/formField';
import { FlexColumn, FlexRow } from '../../element';
import styled from 'styled-components';

const steps = ['Information établissement', 'Detail établissement', 'Créneaux'];

const HideXs = styled.div`
  @media screen and (max-width: 850px) {
    display: none;
  }
  display: flex;
`

export default function PlanningScreen() {

  const [form, setForm] = useState<ICreneaux[] | undefined>(undefined);
  const formik = useFormik({
    initialValues: {
        name: "",
        adresse: "",
        postCode: "",
        city: "",
        telephone: "",
        mail: "",
        accesibility: "",
        material: "",
        logiciel: "",
        salaire: "",
        nombreOphta: "",
        parcours: "",
        nbPatient: "",
        nameCenter: "",
        facturationByMedecin: "",
        assistant: "",
        dispo: false,
        date: "",
        crenaux: "",
        recurrenceDate: false,
    },
    validationSchema:{validations},
    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2));
      const api = new ApiSheetService();
      // api.postFormulaire(form!, "Centres").then((res) => {
      //   console.log(res.data);
          handleNext();
      // })
      // .catch(e => console.log(e.response.data))
    },
  });
  
  const postForm = (form: ICreneaux[]) => {
    setDisable(!disable);
    const api = new ApiSheetService();
    api.postFormulaire(form, "Centres").then((res) => {
      console.log(res.data);
      handleNext();
      setDisable(!disable);

    })
    .catch(e => {
      console.log(e.response.data);
      setDisable(!disable);
    })
  }
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const isLastStep = activeStep === steps.length - 1;
  const currentValidation = validations[activeStep];
  const { formField } = formulaire;
  const [disable, setDisable] = useState(false);
  // const isStepOptional = (step: number) => {
  //   return step === 1;
  // };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     // You probably want to guard against something like this,
  //     // it should never occur unless someone's actively trying to break something.
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const getStepContent = (activeStep: number, formData: any) => {
    switch (activeStep) {
      case 0: 
        return <React.Fragment><FormFieldFirst formData={formData}/></React.Fragment>;
      case 1:
        return <React.Fragment><FormFieldSecond formData={formData}/></React.Fragment>;
      case 2:
        return <React.Fragment><Planning formData={formData} setForm={setForm} form={form!} post={postForm}/></React.Fragment>;
      case 3:
        return <><Box sx={{display:"flex", justifyContent: "center", textAlign:"center", margin:"100px 10% 10px 10%"}}>
            <Typography variant="h4">
              Merci pour vos informations
            </Typography>
          </Box>
          <Box sx={{display:"flex", justifyContent: "center", margin:"50px 10% 10px 10%"}}>
            <img src={Logo} alt='logo SOS Ophta' />
          </Box>
          <Box sx={{display:"flex", justifyContent: "center", textAlign:'center', margin:"50px 10% 10px 10%"}}>
            <Typography variant='body1'>
              Vous serez contacté prochainement <br /> par un membre de notre équipe
            </Typography>
          </Box></>
    }
  }

  return (
    <Box sx={{display:'flex', flexDirection:'row', width: '100%',  backgroundColor:"#FFFFFF", borderRadius:"0 0 20px 20px", minHeight:"70vh"}}>
        <HideXs>
          <FlexRow>
              <Box sx={{display:'flex', width:'100%', justifyContent:'center'}}>
                  <img src={SosImg} alt="SOS Ophta" height={'650px'} width={'auto'}/>
              </Box>
          </FlexRow>
        </HideXs>
        <FlexColumn>
          <HideXs>
            <Stepper activeStep={activeStep} style={{alignItems: "center", margin:"10px 10% 10px 10%"}}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              
              
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel >{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          </HideXs>
          <Formik
            initialValues={{
              name: "",
              adresse: "",
              postCode: "",
              city: "",
              telephone: "",
              mail: "",
              accesibility: "",
              material: "",
              logiciel: "",
              salaire: "",
              nombreOphta: "",
              parcours: "",
              nbPatient: "",
              nameCenter: "",
              facturationByMedecin: "",
              assistant: "",
              dispo: false,
              date: "",
              crenaux: "",
              recurrenceDate: false,
          }}
            // validationSchema={currentValidation}
            onSubmit={(values) => {
              console.log('ok')
              isLastStep ? postForm(form!) : setActiveStep(activeStep + 1);
              }}
            >
          {({ values, errors, touched,handleChange, handleSubmit}) => (
            <Form autoComplete='on'>
              {getStepContent(activeStep,{
                                values,
                                touched,
                                formField,
                                errors,
                                handleChange,
                })}
              { activeStep === 3 ? null :<>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 , alignItems: "center", margin:"0px 10% 30px 10%", paddingBottom:"30px"}}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {data.back}
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button  type="submit" disabled={disable}>
                  { isLastStep ? data.finish : data.next}
                </Button>
                </Box>
              </>
              }
            </Form>
          )}
        </Formik>
      </FlexColumn>
    </Box>
  );
}