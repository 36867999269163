import React, { FC, ReactElement } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Logo from "../../asset/Logo.png";

export const Footer: FC = (): ReactElement => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        paddingTop: "1rem",
        marginTop: "1rem",
        backgroundColor:"#252b61", 
        
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12} style={{margin:'10px 0 20px'}}>
            <Typography color="white" variant="h4" style={{fontFamily:"Orienta"}}>
              <img src={Logo} alt="Logo" style={{width:"100px"}}/>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="white" variant="caption" style={{fontFamily:"Orienta"}}>
              {`Copyright © ${new Date().getFullYear()} |  SOS Ophta all right reserved `}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;