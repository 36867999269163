const form = {
    formField: {
        namer: {
            name: "name",
            label: "Nom du responsable",
            type: "text",
            errorMsg: "Votre nom est requis.",
        },
        nameCentre: {
            name: "nameCenter",
            label: "Raison sociale",
            type: "text",
            errorMsg: "Le nom de votre centre est requis.",
        },
        address: {
            name: "adresse",
            label: "Adresse",
            type: "text",
            errorMsg: "Adresse requise.",
        },
        cityForm: {
            name: "city",
            label: "Ville",
            type: "text",
            errorMsg: "La ville est requis",
        },
        postCodeForm: {
            name: "postCode",
            label: "Code postale",
            type: "number",
            errorMsg: "Code postale requis.",
            invalidMsg: "Code postale est invalide (e.g. 70000).",
        },
        email: {
            name: "mail",
            label: "Adresse Email",
            type: "mail",
            errorMsg: "Votre adresse email est requis.",
            invalidMsg: "Votre adresse email est invalide",
        },
        phoneForm: {
            name: "telephone",
            label: "Téléphone",
            type: "text",
            errorMsg: "Téléphone requis",
            invalidMsg: "Mot de passe incorrecte.",
          },
        accesibilityForm: {
            name: "accesibility",
            label: "Avantage et accessibilité",
            type: "text",
            errorMsg: "Obligatoire",
            invalidMsg: "Obligatoire",
        },
        materialForm: {
            name: "material",
            label: "Matériel",
            type: "text",
            errorMsg: "La liste des matériel est requis",
            invalidMsg: "La liste des matériel est requis",
        },
        logicielForm: {
            name: "logiciel",
            label: "Logiciel",
            type: "text",
            errorMsg: "La liste des logiciel est requis",
            invalidMsg: "La liste des logiciel est requis",
        },
        salaireForm: {
            name: "salaire",
            label: "Salaire",
            type: "text",
            errorMsg: "Le montant du salaire est requis",
            invalidMsg: "Le montant du salaire est requis",
        },
        nbOrthoForm: {
            name: "nombreOphta",
            label: "Nombre d'orthoptiste",
            type: "text",
            errorMsg: "Le nombre d'orthoptiste est requis",
            invalidMsg: "Le nombre d'orthoptiste est requis",
        },
        nbPatientForm: {
            name: "nbPatient",
            label: "Moyenne de patient",
            type: "text",
            errorMsg: "La moyenne des patients est requise",
            invalidMsg: "La moyenne des patients est requise",
        },
        parcoursForm: {
            name: "parcours",
            label: "Parcours de soin",
            type: "text",
            errorMsg: "Le parcours de soin est requis",
            invalidMsg: "Le parcours de soin est requis",
        },
        assistantForm: {
            name: "assistant",
            label: "Travail aidé par un assistant ? *",
            type: "radio",
            errorMsg: "error",
            invalidMsg: "error"
        },
        facturationForm: {
            name: "facturationByMedecin",
            label: "Facturation et cotation faite par le médecin ou à l'accueil ? *",
            type: "radio",
            errorMsg: "error",
            invalidMsg: "error"
        },
    }
}

export default form;