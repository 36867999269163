import * as Yup from "yup";

const validations = 
[
    Yup.object().shape({
        name: Yup.string().required("Name is required"),
        nameCenter: Yup.string().required("Name center is required"),
        adress: Yup.string().required("Adress is required"),
        city: Yup.string().required("City is required"),
        postCode: Yup.string().required("postCode is required"),
        mail:  Yup.string().required("email is required"),
        telephone: Yup.string().required("Phone is required").min(10, "phone.errorMsg").max(13, "phone.errorMsg"),
    }),
    Yup.object().shape({
        material: Yup.string().required("Material list is required"),
        logiciel: Yup.string().required("Logiciel list is required"),
        salaire: Yup.string().required("Salaire is required"),
        nombreOphta: Yup.string().required("Nombre ortho is required"),
        nbPatient: Yup.string().required("Patient number is required"),
        parcours: Yup.string().required("Parcours is required"),
        assistant: Yup.string().required("Champs obligatoire"),
        facturationByMedecin: Yup.string().required("champs obligatoire"),
    })
];

export default validations;