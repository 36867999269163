import { ICreneaux } from "../../data/data";
import { BASE_URL } from "../API_URL";
import RequestServices from "../RequestServices";

class ApiSheetService {
    postFormulaire(data: ICreneaux[], info: string){
        let url = `${BASE_URL}/formulaire/${info}`;
        return RequestServices.postRequest(url, data);
    }

}

export default ApiSheetService;