import { Box, Grid, Typography } from '@mui/material'
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useState } from 'react';
import styled from 'styled-components'

interface IDayCell {
    index: number,
    day: string;
    onClick: (date: string, crenaux: string, recurrenceDate: boolean, id: number) => void;
    deleteCrenaux: (id: number) => void
}

const DivG = styled.div`
    width: "100%";
    height: 300px;
    align-items: center;
    @media screen and (max-width: 650px) {
    width: "50%";
  }
`

const Hidemin = styled.div`
    @media screen and (max-width: 850px) {
    display: none;
    //background-color: yellow;
  }
`

const Hidemax = styled.div`
    display: none;
    @media screen and (max-width: 850px) {
    //background-color: red;
    display: flex;
  }
`

const DayCell: React.FC<IDayCell> = ({day, onClick, index, deleteCrenaux}) => {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const date = Number(day.split('-')[2]);
    const dayDate = format(new Date(year, month, date), 'dd'); 
    const [cellMatin, setCellMatin] = useState(false);
    const [cellAprem, setCellAprem] = useState(false);
    
  return (
    <Grid item xs={4} sm={4} lg={2} style={{width:'100%'}}>
        <Box sx={{width:'100%', height:200, alignItems:'center'}}>

                <Hidemin>
                    <div style={{width:'100%', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                        <Typography variant='subtitle1'>{format(new Date(year, month, date), 'EEEE', { locale: fr }).slice(0,3)}.</Typography>
                        <Typography variant='subtitle1' style={{marginTop: "2vh", marginBottom: "2vh"}}>{dayDate + ' '}</Typography>
                        <Typography variant='subtitle1'>{format(new Date(year, month, date), 'MMMM', { locale: fr })}</Typography>
                    </div>
                </Hidemin>
                <Hidemax>
                    <div style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                        <Typography variant='subtitle1'>{dayDate + ' '}</Typography>
                        <Typography variant='subtitle1'>{format(new Date(year, month, date), 'MMMM', { locale: fr })} </Typography>
                    </div>
                </Hidemax>
                <Box
                    sx={{
                        width: '90%',
                        height: 50,
                        borderRadius: 2,
                        borderStyle: 'solid',
                        borderColor: cellMatin ? '#82E0AA' : '#CBCBCB',
                        justifyContent: 'center',
                        alignItems:'center',
                        display: 'flex',
                        backgroundColor: cellMatin ? '#82E0AA' : 'primary.white',
                        borderWidth: 1,
                        '&:hover': {
                        backgroundColor:'primary.main',
                        opacity: [0.9, 0.8, 0.7],
                        },
                    }}
                    onClick={() => {
                        setCellMatin(!cellMatin);
                        !cellMatin ?
                        onClick(format(new Date(year, month, date), 'yyyy-MM-dd'), "matin", false, (index * 2)) :
                        deleteCrenaux(index * 2);
                    }}
                >
                    <Typography variant="body1" style={cellMatin ? {color: "white" } : {color : 'black'}}>{cellMatin ? "Disponible" : "Matin"}</Typography>
                </Box>
                <Box
                    sx={{
                        marginTop: 2,
                        width: '90%',
                        height: 50,
                        borderRadius: 2,
                        justifyContent: 'center',
                        alignItems:'center',
                        display: 'flex',
                        backgroundColor: cellAprem ? '#82E0AA' :'primary.white',
                        borderStyle: 'solid',
                        borderColor: cellAprem ? '#82E0AA' :"#CBCBCB",
                        borderWidth: 1,
                        '&:hover': {
                        backgroundColor: 'primary.main',
                        opacity: [0.9, 0.8, 0.7],
                        },
                    }}
                    onClick={() => {
                        setCellAprem(!cellAprem);
                        !cellAprem ?
                        onClick(format(new Date(year, month, date), 'yyyy-MM-dd'), "apres-midi", false, (index * 2) + 1) : deleteCrenaux((index * 2) + 1);}}
                >
                    <Typography variant="body1" style={cellAprem ? {color: "white", textAlign:'center' } : {color : 'black', textAlign:'center'}}>{cellAprem ? "Créneau" : "Aprês-midi"}</Typography>
                </Box>
        </Box>
    </Grid>
  )
}

export default DayCell;