import React from "react";
import { Box, Button, Checkbox, FormControl, FormControlLabel, Input, InputAdornment, InputLabel, TextField, Typography} from "@mui/material";
import FlexColumn from "../../element/Flex/flexColumn";
import FlexRow from "../../element/Flex/flexColumn";

interface IForm {
    formData: any;
}

const FormFieldFirst: React.FC<IForm> = ({formData}) => {
    const { formField, values, errors, touched, handleChange } = formData;
    const { email, nameCentre, namer, address, cityForm, postCodeForm, phoneForm, accesibilityForm } = formField;

     const {
        nameCenter,
        mail,
        name,
        adresse,
        city,
        postCode,
        telephone,
        accesibility
    } = values

    return(
        <Box sx={{ alignItems: "center", margin:"10px 10% 10px 10%"}}>
            <FlexColumn>
                <FlexColumn>
                    <TextField id="outlined-basic" 
                    label={nameCentre.label} 
                    variant="outlined" 
                    style={{margin:"10px 0 10px 0"}}
                    name={nameCentre.name}
                    type={nameCentre.type}
                    required
                    onChange={handleChange}
                    value={nameCenter}
                    error={errors.nameCenter}/>

                    <TextField id="outlined-basic" label={address.label} variant="outlined" style={{margin:"10px 0 10px 0"}} name={address.name} type={address.type} required value={adresse} onChange={handleChange}/>
                    <FlexRow style={{width:'100%'}}>
                        <TextField id="outlined-basic" label={cityForm.label} variant="outlined" style={{margin:"10px 10px 10px 0", width:'100%'}} name={cityForm.name} type={cityForm.type} required
                            onChange={handleChange} value={city}/>
                        <TextField id="outlined-basic" label={postCodeForm.label} variant="outlined" style={{margin:"10px 0 10px 0", width:'100%'}} name={postCodeForm.name} type={postCodeForm.type} required
                            onChange={handleChange} value={postCode}/>
                    </FlexRow>
                    <TextField id="outlined-basic"  label={namer.label} variant="outlined" style={{margin:"10px 0 10px 0"}} name={namer.name} type="name" required value={name}  onChange={handleChange}/>
                    <FlexRow style={{width:'100%'}}>

                        <TextField id="outlined-basic" 
                        label={email.label}
                        variant="outlined"
                        style={{margin:"10px 10px 10px 0", width:'100%'}}
                        name={email.name}
                        type={email.type}
                        onChange={handleChange}
                        required
                        value={mail}
                        error={errors.email}
                    />

                        <TextField id="outlined-basic" label={phoneForm.label} variant="outlined" style={{margin:"10px 0 10px 0", width:'100%'}} name={phoneForm.name} type={phoneForm.type} required
                            onChange={handleChange} value={telephone}/>
                    </FlexRow>
                    <TextField id="outlined-multiline-static" multiline rows={4} label={accesibilityForm.label} 
                        variant="outlined" style={{margin:"10px 0 10px 0"}} name={accesibilityForm.name} type={accesibilityForm.type} 
                        onChange={handleChange} value={accesibility}/>
                </FlexColumn>
            </FlexColumn>
        </Box>
    );
};


export default FormFieldFirst;

